@import 'bootstrap/dist/css/bootstrap.css';


html,
body {
    height: 100%;
    max-width: 640px;
    margin: auto;
    background: linear-gradient(180deg, #050052 0%, #1B1C25 100%);
    color: #ffffff;
}

#root {
    height: 100%;
}

// Button

.btn {
    border-radius: 2rem;
    font-weight: bold;

    &:active,
    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}

.btn-primary {
    background: linear-gradient(152.22deg, #FF1998 -4.79%, #FFB6DE 88.63%);
    border: #FF1998;
    color: #353539;

    &:active,
    &:hover,
    &:focus,
    &:disabled {
        color: #353539 !important;
        border: #FF1998 !important;
    }
}

.btn-secondary {
    background: #ffffff;
    border: #0BEC8D;
    color: #0BEC8D;

    &:active,
    &:hover,
    &:focus,
    &:disabled {
        color: #0BEC8D !important;
        border: #0BEC8D !important;
    }
}

// Form Control

.form-control {
    border-radius: 2rem;
    font-weight: 500;
    background-color: transparent;
    color: #fff;

    &:active,
    &:focus {
        outline: none !important;
        box-shadow: none !important;
        background-color: transparent;
        color: #fff;
    }
}

// Switch

.custom-control-input:checked~.custom-control-label::before {
    border-color: #0BEC8D;
    background-color: #0BEC8D;
}