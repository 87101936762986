.account {
    padding: 1rem;
    height: 100%;
    transition: all 250ms ease-in-out;

    &.invalid {
        background-color: rgba($color: #dc3545, $alpha: 0.5);
    }

    .header {
        height: 10%;

        .back-icon {
            font-size: 1.5rem;
            color: #ffffff;
            padding: 1rem 0;
        }
    }

    .main {
        height: 80%;
    }

    .footer {
        height: 10%;
    }
}