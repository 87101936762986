.home {
    height: 100%;
    padding: 1rem;

    &.success {
        background: linear-gradient(316.59deg, #0BEC8D 1.1%, #14ADCF 98.47%);
    }

    .header {
        height: 10%;

        .back-icon,
        .settings-icon {
            font-size: 1.5rem;
            color: #ffffff;
            padding: 1rem 0;
        }

        .settings-icon {
            float: right;
        }
    }

    .main {
        height: 80%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .footer {
        height: 10%;
    }
}