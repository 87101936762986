.keypad {
    width: 100%;
    font-size: 2rem;
    table-layout: fixed;
    text-align: center;

    tbody {
        tr {
            td {
                padding: 0.25rem;
            }
        }
    }
}