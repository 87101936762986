@-webkit-keyframes shake {
  0% {
    -webkit-transform: translateX(0);
  }

  20% {
    -webkit-transform: translateX(-10px);
  }

  40% {
    -webkit-transform: translateX(10px);
  }

  60% {
    -webkit-transform: translateX(-10px);
  }

  80% {
    -webkit-transform: translateX(10px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(-10px);
  }

  40% {
    transform: translateX(10px);
  }

  60% {
    transform: translateX(-10px);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}

.pin-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;

  .pin {
    margin: 3rem 0;

    &.pin-invalid {
      -webkit-animation: shake .5s ease-in-out;
      animation: shake .5s ease-in-out;
    }

    .pin-char {
      margin: 0 0.25rem;
    }
  }
}